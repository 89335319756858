
import * as Sentry from "@sentry/vue";
import config from "./config";
import "./bootstrap";
import { createApp, defineAsyncComponent, h } from "vue";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { createInertiaApp, Link } from "@inertiajs/vue3";
import Vapor from "laravel-vapor";
import localization from "./Localization";
import vClickOutside from "click-outside-vue3";
import {
    useAssetTransforms,
    useGlobalRouting,
    useHelpers,
} from "./Composables";
import ClientOnly from "@/Helpers/ClientOnly";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import { Ziggy } from "@/ziggy";
import _ from "lodash";
import BaseSvg from "@/Components/Modules/BaseSvg.vue";
import { Layout } from "@/Layouts";
import PrimeVue from 'primevue/config';

import.meta.glob(["../img/**", "../fonts/**"]);

Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);

createInertiaApp({
    progress: {
        showSpinner: false,
    },
    title: (title) => `${title}`,
    resolve: async (name) => {
        const page = await resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        );

        page.default.layout = [Layout];

        return page;
    },
    setup({ el, App, props, plugin }) {
        let instance = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(PrimeVue)
            .use(localization)
            .use(vClickOutside)
            .use(ZiggyVue, Ziggy)
            .provide("$upload", Vapor.store)
            .provide("DynamicSettings", DynamicSettings)
            .provide("inert", useGlobalRouting().inert)
            .mixin({
                data() {
                    return {
                        inert: useGlobalRouting().inert,
                        _,
                    };
                },
                methods: {
                    showMeTheMoney: useHelpers().showMeTheMoney,
                    asset: Vapor.asset,
                    assetUrl: useAssetTransforms().assetUrl,
                    marketingAssetUrl: useAssetTransforms().marketingAssetUrl,
                    productImage: useAssetTransforms().productImage,
                    productCardImage: useAssetTransforms().productCardImage,
                    back:() => window.history.back(),
                },
            })
            .component("Link", Link)
            .component("BaseSvg", BaseSvg)
            .component("ClientOnly", ClientOnly)
            .component(
                "ApexChart",
                defineAsyncComponent(() => {
                    return import("vue3-apexcharts");
                })
            );

        Sentry.init({
            app: instance,
            debug: props.initialPage.props.sentry.environment !== "production",
            release: props.initialPage.props.sentry.release,
            dsn: props.initialPage.props.sentry.dsn,
            environment: props.initialPage.props.sentry.environment,
            integrations: [
                Sentry.replayIntegration({
                    maskAllText: props.initialPage.props.environment === "production",
                }),
                Sentry.browserTracingIntegration(),
                Sentry.httpClientIntegration(),
                Sentry.reportingObserverIntegration()

            ],
            enableTracing: true,
            tracesSampleRate: props.initialPage.props.sentry.traces_sample_rate,
            // Session Replay
            replaysSessionSampleRate: props.initialPage.props.sentry.sample_rate,
            replaysOnErrorSampleRate: props.initialPage.props.sentry.sample_rate,
        });


        //make sure we log any uncaught errors, the logger has a rollbar hook
        instance.config.errorHandler = function (err, vm, info) {
            Sentry.captureException(err);
            if(props.initialPage.props.sentry.environment !== "production"){
                console.error(err);
            }
        };
        return instance.mount(el);
    },
});


// get_ga_clientid: function ()
// {
//     var cookie = {};
//     document.cookie.split(';').forEach(function(el) {
//         var splitCookie = el.split('=');
//         var key = splitCookie[0].trim();
//         var value = splitCookie[1];
//         cookie[key] = value;
//     });
//     var result = cookie["_ga"] ? cookie["_ga"].substring(6) : '';
//     return result;
// }
